import classNames from "classnames";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function TablePagination({ onNext, onPrev, onJump, current, total }) {
  const [page, setPage] = useState(current);
  const disablePrev = current <= 1;
  const disableNext = current >= total;
  const maxDecimals = total.toString().length;

  const handlePageChange = ({ target: { value } }) => {
    value = value.replaceAll(/\D/g, "");
    let page = 0;
    if (value.length > 0) {
      page = Number.parseInt(value);
    }
    setPage(page);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onJump(page);
    }
  };
  useEffect(() => setPage(current), [current]);
  return (
    <div className="w-full py-4 px-8 flex items-center justify-end">
      <button
        type="button"
        className={classNames(
          "py-2 px-4 rounded-full border-0 focus:outline-none text-xs",
          {
            "text-gray-300": disablePrev,
            "text-gray-500 hover:bg-violet-100 active:bg-violet-200":
              !disablePrev,
          }
        )}
        disabled={disablePrev}
        onClick={onPrev}
      >
        <i className="fas fa-chevron-left"></i>
      </button>
      <div className="mx-16 text-xs">
        <input
          className="inline-block mr-1 p-1 border-0 rounded focus:ring ease-linear transition-all duration-150 text-center"
          size={maxDecimals}
          value={page}
          onChange={handlePageChange}
          onKeyPress={handleKeyPress}
        />{" "}
        of {total}
      </div>
      <button
        type="button"
        className={classNames(
          "py-2 px-4 rounded-full border-0 focus:outline-none text-xs",
          {
            "text-gray-500 hover:bg-violet-100 active:bg-violet-200":
              !disableNext,
            "text-gray-300": disableNext,
          }
        )}
        disabled={disableNext}
        onClick={onNext}
      >
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
}

TablePagination.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onJump: PropTypes.func.isRequired,
};

export default TablePagination;
