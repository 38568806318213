import Card from "components/Cards/Card.js";
import LoadingTableCard from "components/Cards/LoadingTableCard.js";
import Cell from "components/Table/Cell.js";
import HeadingCell from "components/Table/HeadingCell.js";
import Table from "components/Table/Table.js";
import TablePagination from "components/Table/TablePagination.js";
import { firestoreBase } from "constants.js";
import { format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import { Suspense, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useFirestore, useFirestoreCollection } from "reactfire";

const roles = {
  admin: "Administrator",
  moderator: "Moderator",
  inspector: "Inspector",
};

function Staff(props) {
  const headings = ["Name", "Email", "Role", "Date Created"];
  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <Suspense
          fallback={<LoadingTableCard {...props} headings={headings} />}
        >
          <StaffCard {...props} headings={headings} />
        </Suspense>
      </div>
    </div>
  );
}

Staff.defaultProps = {
  title: "Staff",
  numRows: 10,
};

Staff.propTypes = {
  title: PropTypes.string,
  numRows: PropTypes.number.isRequired,
  action: PropTypes.node,
};

function StaffCard({ title, headings, numRows, action }) {
  const firestore = useFirestore();
  const query = useMemo(() => {
    return firestore
      .doc(firestoreBase)
      .collection("staff")
      .orderBy("createdAt", "desc");
  }, [firestore]);
  const { data: collection } = useFirestoreCollection(query);

  const location = useLocation();
  const startPage =
    location.state?.page ?? (collection.docs.length > 0 ? 1 : 0);
  const [page, setPage] = useState(startPage);
  const numPages = Math.ceil(collection.docs.length / numRows);

  const nextPage = useCallback(
    () => setPage((page) => Math.min(page + 1, numPages)),
    [numPages]
  );
  const prevPage = useCallback(
    () => setPage((page) => Math.max(1, page - 1)),
    []
  );
  const goToPage = useCallback(
    (newPage) => setPage(Math.min(Math.max(1, newPage), numPages)),
    [numPages]
  );
  return (
    <Card title={title} action={action}>
      <Table>
        <thead>
          <tr>
            {headings.map((heading) => (
              <HeadingCell key={heading}>{heading}</HeadingCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {collection.docs
            .slice((page - 1) * numRows, page * numRows)
            .map((doc) => (
              <StaffRow key={doc.id} doc={doc} />
            ))}
        </tbody>
      </Table>
      <hr />
      <TablePagination
        current={page}
        total={numPages}
        onNext={nextPage}
        onPrev={prevPage}
        onJump={goToPage}
      />
    </Card>
  );
}

function StaffRow({ doc }) {
  const staff = doc.data();
  return (
    <tr className="hover:bg-blueGray-50 hover:cursor-pointer">
      <Cell>{staff.name}</Cell>
      <Cell>
        <a href={`mailto:${staff.email}`}>{staff.email}</a>
      </Cell>
      <Cell>{roles[staff.role]}</Cell>
      <Cell>
        {formatDate(staff.createdAt.toDate(), "ccc dd LLL hh:mm a (O)")}
      </Cell>
    </tr>
  );
}

export default Staff;
