import HeaderStats from "components/Headers/HeaderStats.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import PrivateRoute from "components/Routes/PrivateRoute.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import useRoleCheck from "hooks/useRoleCheck.js";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "views/admin/Dashboard.js";
import Orders from "views/admin/Orders.js";
import OrdersDetails from "views/admin/OrdersDetails.js";
import Staff from "views/admin/Staff.js";
import Transactions from "views/admin/Transactions.js";
import TransactionsNew from "views/admin/TransactionsNew.js";
import TransactionsUpload from "views/admin/TransactionsUpload.js";

export default function Admin() {
  const { isAdmin } = useRoleCheck();
  return (
    <>
      <Sidebar admin={isAdmin} />
      <div className="relative min-h-screen md:ml-64 bg-blueGray-100 pb-12">
        <AdminNavbar />
        {/* Header */}
        <div className="relative bg-orange-500 md:pt-32 pb-32 pt-12">
          {isAdmin ? <HeaderStats /> : null}
        </div>
        <div className="px-4 md:px-10 mx-auto w-full -mt-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/orders" exact component={Orders} />
            <Route
              path="/admin/new-orders"
              exact
              component={() => <Orders status="new" />}
            />
            <Route path="/admin/orders/:id" exact component={OrdersDetails} />
            <Route path="/admin/transactions" exact component={Transactions} />
            <Route
              path="/admin/transactions/new"
              exact
              component={TransactionsNew}
            />
            <Route
              path="/admin/transactions/upload"
              exact
              component={TransactionsUpload}
            />
            <PrivateRoute admin exact path="/admin/staff" component={Staff} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
        </div>
      </div>
    </>
  );
}
