import classNames from "classnames";
import { firestoreBase, OrderShares, OrderStatus } from "constants.js";
import useRoleCheck from "hooks/useRoleCheck.js";
import React, {
  Component,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocData, useFunctions } from "reactfire";
import "semantic-ui-css/semantic.min.css";
import { Dropdown, Form, Grid, Icon } from "semantic-ui-react";

// TODO: Copart red for required.
// TODO: Copart all the colors.

const errorLabels = {
  "tickets-not-issued":
    "Some of the tickets requested may be out of stock, or the TicketNile API maybe misbehaving. Please do not confirm anymore orders and immediatley contact us!",
  "qr-not-generated":
    "The tickets have been generated and saved, but could not be sent. Please do not confirm anymore orders and immediatley contact us!",
  "tickets-not-sent":
    "The tickets have been generated and saved, but could not be sent. Please do not confirm anymore orders and immediatley contact us!",
};

export default function OrderDetails() {
  const { id } = useParams();
  const FieldValue = useFirestore.FieldValue;
  const firestore = useFirestore();
  const orderRef = useMemo(
    () => firestore.doc(firestoreBase).collection("orders").doc(id),
    [firestore, id]
  );
  const { data: order } = useFirestoreDocData(orderRef);

  const { user, isAdmin, isModerator } = useRoleCheck();
  const userRef = useMemo(
    () => firestore.doc(firestoreBase).collection("staff").doc(user.uid),
    [firestore, user.uid]
  );

  const assignOrder = useCallback(async () => {
    await orderRef.update({ assignedTo: userRef });
  }, [orderRef, userRef]);
  // Assign order to user if not admin
  useEffect(() => {
    if (isModerator) {
      assignOrder();
    }
  }, [isModerator, assignOrder]);

  // log status changes
  const handleStatusChange = useCallback(
    async (status) => {
      await orderRef.update({
        assignedTo: false,
      });
      orderRef.collection("changelog").add({
        status,
        user: userRef,
        time: FieldValue.serverTimestamp(),
      });
    },
    [FieldValue, orderRef, userRef]
  );

  const carPhotos = order.car_photos_urls.map((url) => ({
    src: url,
  }));
  const personalPhoto = order.pres_photo_urls.map((url) => ({
    src: url,
  }));
  const credentialPhoto = order.credentials_urls.map((url) => ({
    src: url,
  }));
  const emergecyPhoto = order.emerg_trans_urls.map((url) => ({
    src: url,
  }));
  const carCredentialPhoto = order.car_cred_urls.map((url) => ({
    src: url,
  }));

  const allImages = personalPhoto.concat(
    credentialPhoto,
    carCredentialPhoto,
    emergecyPhoto,
    carPhotos
  );
  //const ek =
  console.log(carPhotos);
  console.log(allImages);
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 p-8 shadow-lg rounded">
      <div className="flex flex-wrap justify-center gap-4 text-center text-blueGray-600 ">
        <span className="w-full lg:w-3/12">
          <i className="fas fa-user mr-2 text-lg"></i>
          {order.name} ({order.age})
        </span>
        <span className="w-full lg:w-3/12">
          <i className="fas fa-map-marker mr-2 text-lg"></i>
          {order.address}
        </span>
        <span className="w-full lg:w-3/12">
          <i className="fas fa-phone mr-2 text-lg"></i>
          {order.call_phone}
        </span>

        <span className="w-full lg:w-4/12">
          <i
            className={classNames("fas mr-2 text-lg", {
              "fa-circle": order.status === "new",
              "fa-calendar-times": order.status === "expired",
              "fa-eye-slash": order.status === "ignored",
              "fa-spinner": order.status === "confirmed",
              "fa-check": order.status === "fulfilled",
              "fa-exclamation-circle": order.status === "error",
            })}
          ></i>
          {OrderStatus[order.status]}
        </span>

        <span className="w-full lg:w-3/12">
          <i className="fas fa-briefcase mr-2 text-lg"></i>
          {order.job_loc} ({order.job})
        </span>

        <span className="w-full lg:w-4/12 font-mono">
          <i className="fas fa-id-badge mr-2 text-lg"></i>
          {order.cred_num} (Exp. {order.cred_exp})
        </span>
      </div>
      <hr className="mt-4 text-blueGray-600 border-1" />
      <div className="mt-4 flex flex-wrap text-sm gap-4 justify-center">
        {Object.keys(OrderShares).map((ticketClass) =>
          order[ticketClass] > 0 ? (
            <span className="text-white bg-violet-500 font-bold py-1 px-2 rounded">
              {order[ticketClass]} × {OrderShares[ticketClass]}
            </span>
          ) : null
        )}
      </div>
      {order.status === "error" ? (
        <h6 className="text-center text-sm text-red-500">
          {errorLabels[order.errorCode]}
        </h6>
      ) : null}
      {isAdmin || (isModerator && order.status === "new") ? (
        <ManageStatus
          docRef={orderRef}
          order={order}
          onStatusChange={handleStatusChange}
        />
      ) : null}
      {allImages.length > 0 ? (
        <div className="mt-8">
          <Carousel
            images={allImages}
            style={{ width: "100%", height: "32rem" }}
            isLoop={false}
            canAutoPlay={false}
            objectFit="contain"
          />
        </div>
      ) : (
        <p className="text-xs text-gray-400">
          The customer has not sent an image as of yet
        </p>
      )}
    </div>
  );
}

function ManageStatus({ docRef, order, onStatusChange }) {
  const FieldValue = useFirestore.FieldValue;
  const firestore = useFirestore();
  const tiersRef = firestore
    .doc(firestoreBase)
    .collection("messages")
    .doc("5W2d0UMYmzRbA4tysZww");
  const { data: options } = useFirestoreDocData(tiersRef);
  const sendVideo = useFunctions().httpsCallable("sendVideo");
  const allOptions = options["options"].map((option) => ({
    key: option,
    text: option,
    value: option,
  }));
  const [processing, setProcessing] = useState(false);
  const [canConfirm, setCanConfirm] = useState(true);
  const [carSubType, setCarSubType] = useState("");
  const handleConfirm = async () => {
    setProcessing(true);
    try {
      await docRef.update({
        status: "confirmed",
        confirmedAt: FieldValue.serverTimestamp(),
        car_sub_type: carSubType,
      });
      await sendVideo({
        recipient: `${order.user_id}`,
        header:
          'الرجاء مشاهدة فيديو الورشة التدريبة على الرابط التالي ثم الضغط على زر "تم" للإقرار بمشاهدة الفيديو https://www.youtube.com/watch?v=EwTZ2xpQwpA',
        title: "تم",
      });
      await Promise.resolve(onStatusChange("confirmed"));
      setProcessing(false);
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };
  const handleIgnore = async () => {
    setProcessing(true);
    try {
      await docRef.update({
        status: "ignored",
        ignoredAt: FieldValue.serverTimestamp(),
      });
      await Promise.resolve(onStatusChange("ignored"));
      setProcessing(false);
    } finally {
      setProcessing(false);
    }
  };
  return (
    <>
      <TierOption
        allOptions={allOptions}
        carSubType={carSubType}
        setCarSubType={setCarSubType}
        canConfirm={canConfirm}
        setCanConfirm={setCanConfirm}
      />
      <ActionOption uid={order.user_id} />

      <div className="flex mt-8">
        {order.status === "new" ? (
          <button
            onClick={handleIgnore}
            className={classNames(
              "py-2 px-4 rounded border-0 shadow uppercase font-bold text-xs",
              {
                "text-white bg-gray-300": processing,
                "text-white bg-red-500 focus:outline-none hover:bg-red-400 hover:shadow-md active:bg-red-600":
                  !processing,
              }
            )}
          >
            Ignore
          </button>
        ) : null}
        <div className="flex-1"></div>
        <button
          onClick={handleConfirm}
          disabled={!canConfirm}
          className={classNames(
            "py-2 px-4 rounded border-0 shadow uppercase font-bold text-xs",
            {
              "text-white bg-gray-300": processing || !canConfirm,
              "text-white bg-emerald-500 focus:outline-none hover:bg-emerald-400 hover:shadow-md active:bg-emerald-600":
                !processing && canConfirm,
            }
          )}
        >
          Confirm
        </button>
      </div>
    </>
  );
}

class TierOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      default: "",
      false: "",
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    this.props.setCarSubType(value);
    this.props.setCanConfirm(true);
  };

  render() {
    console.log(this.props.carSubType);
    return (
      <Grid columns="equal">
        <Grid.Column>
          <Form>
            <Form.Field>
              <label>Select Tier</label>
              <Dropdown
                selection
                name="default"
                options={this.props.allOptions}
                placeholder="Select"
                onChange={this.handleChange}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

function ActionOption(uid) {
  const [option, setOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const messagesRef = useFirestore()
    .doc(firestoreBase)
    .collection("messages")
    .doc("jlpIjIgKzvjsiaN8FFCq");
  const { data: options } = useFirestoreDocData(messagesRef);
  const sendMessage = useFunctions().httpsCallable("sendMessage");
  const allOptions = options["messages "].map((option) => ({
    key: option,
    text: option,
    value: option,
  }));

  useEffect(() => {
    setIsLoading(false);
    setIsSent(false);
  }, [option]);

  const handleChange = (e, { name, value }) => {
    setOption(`${value}`);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await sendMessage({
        recipient: `${uid.uid}`,
        header: option,
        title: "إعادة ارسال",
      });
      setIsSent(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid divided="vertically">
      <Grid.Row>
        <Grid.Column width={10}>
          <Form>
            <Form.Field>
              <label>Select Action</label>
              <Dropdown
                selection
                name="default"
                options={allOptions}
                placeholder="Select"
                onChange={handleChange}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column width={4} verticalAlign={"bottom"}>
          <Form.Button
            color={"orange"}
            size={"medium"}
            loading={isLoading}
            onClick={onSubmit}
          >
            {isSent ? <Icon name="check"></Icon> : <Icon name="send"></Icon>}
          </Form.Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
