export const OrderStatus = {
  new: "New",
  expired: "Expired",
  ignored: "Ignored",
  confirmed: "Confirmed",
  error: "Has Issues",
  fulfilled: "Fulfilled",
};

export const OrderShares = {
  num_Silver_shares: "Silver",
  num_Gold_shares: "Gold",
  num_Platinum_shares: "Platinum",
};

export const firestoreBase = `${process.env.REACT_APP_FIRESTORE_BASE_COLLECTION}/${process.env.REACT_APP_FIRESTORE_BASE_DOCUMENT}`;
