export async function fetchFirebaseConfig() {
  let config;
  if (process.env.NODE_ENV === "production") {
    const res = await fetch("/__/firebase/init.json");
    if (res.ok) {
      try {
        config = await res.json();
      } catch (e) {}
    }
  }
  if (config == null) {
    config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
    };
  }
  return config;
}

export function setupEmulators(app) {
  return app;
}
