import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/index.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import React from "react";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { FirebaseAppProvider } from "reactfire";
import App from "./App.js";
import { fetchFirebaseConfig, setupEmulators } from "./firebaseConfig.js";

async function main() {
  try {
    const config = await fetchFirebaseConfig();
    const firebaseApp = firebase.initializeApp(config);
    await setupEmulators(firebaseApp);
    ReactDOM.render(
      <FirebaseAppProvider firebaseApp={firebaseApp} suspense={true}>
        <App />
      </FirebaseAppProvider>,
      document.getElementById("root")
    );
  } catch (e) {
    console.error("Could not initialize the Firebase SDK");
    console.error(e);
  }
}

main();
