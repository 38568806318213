import PrivateRoute from "components/Routes/PrivateRoute.js";
import { firestoreBase } from "constants.js";
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  preloadAuth,
  preloadFirestore,
  preloadFirestoreDoc,
  preloadFunctions,
  preloadStorage,
  preloadUser,
  useFirebaseApp,
} from "reactfire";

async function preloadSDKs(firebaseApp) {
  return Promise.all([
    preloadAuth({ firebaseApp }),
    preloadFunctions({ firebaseApp }),
    preloadFirestore({ firebaseApp }),
    preloadStorage({
      firebaseApp,
      setup(storage) {
        return storage().setMaxUploadRetryTime(10000);
      },
    }),
  ]);
}

async function preloadData(firebaseApp) {
  const user = await preloadUser({ firebaseApp });
  if (user) {
    preloadFirestoreDoc(
      (firestore) =>
        firestore.doc(firestoreBase).collection("admins").doc(user.uid),
      { firebaseApp }
    );
  }
}

export default function App() {
  const firebaseApp = useFirebaseApp();

  preloadSDKs(firebaseApp).then(() => preloadData(firebaseApp));

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/admin" component={Admin} />
        <Route path="/auth" component={Auth} />
        <Redirect from="*" to="/admin" />
      </Switch>
    </BrowserRouter>
  );
}
