import classNames from "classnames";
import Card from "components/Cards/Card.js";
import LoadingTableCard from "components/Cards/LoadingTableCard.js";
import Cell from "components/Table/Cell.js";
import HeadingCell from "components/Table/HeadingCell.js";
import RowHeadingCell from "components/Table/RowHeadingCell.js";
import Table from "components/Table/Table.js";
import TablePagination from "components/Table/TablePagination";
import { firestoreBase } from "constants.js";
import { format } from "date-fns/fp";
import PropTypes from "prop-types";
import React, { Suspense, useCallback, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFirestore, useFirestoreCollection } from "reactfire";
import { insertIf } from "utils.js";

const statusLabels = {
  new: "New",
  confirmed: "Confirmed",
  refunded: "Refunded",
};

function Transactions({ status, ...props }) {
  const location = useLocation();
  let statusQuery = status;
  if (statusQuery == null) {
    statusQuery = new URLSearchParams(location.search).get("status");
  }
  if (props.title == null) {
    props.title = "Transactions";
    if (statusQuery != null) {
      statusQuery = `${statusLabels[statusQuery]} Transactions`;
    }
  }
  const headings = [
    "Trx. ID",
    "Amount",
    ...insertIf(statusQuery == null, "Status"),
    "Time",
  ];
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Suspense
            fallback={<LoadingTableCard {...props} headings={headings} />}
          >
            <TransactionsCard {...props} headings={headings} />
          </Suspense>
        </div>
      </div>
    </>
  );
}

Transactions.defaultProps = {
  title: "Transactions",
  numRows: 10,
};

Transactions.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.oneOf(["new", "confirmed", "refunded"]),
};

function TransactionsCard({ title, headings, status, numRows }) {
  const firestore = useFirestore();
  const query = useMemo(() => {
    let query = firestore
      .doc(firestoreBase)
      .collection("transactions")
      .orderBy("time", "desc");
    if (status) {
      query = query.where("status", "==", status);
    }
    return query;
  }, [firestore, status]);
  const { data: collection } = useFirestoreCollection(query);

  const location = useLocation();
  const startPage =
    location.state?.page ?? (collection.docs.length > 0 ? 1 : 0);
  const [page, setPage] = useState(startPage);
  const numPages = Math.ceil(collection.docs.length / numRows);
  const nextPage = useCallback(
    () => setPage((page) => Math.min(numPages, page + 1)),
    [numPages]
  );
  const prevPage = useCallback(
    () => setPage((page) => Math.max(1, page - 1)),
    []
  );
  const goToPage = useCallback(
    (page) => setPage(() => Math.min(Math.max(1, page), numPages)),
    [numPages]
  );
  return (
    <Card
      title={title}
      action={
        <>
          <Link
            to="transactions/upload"
            role="button"
            className="mr-2 py-2 px-4 rounded border-2 shadow-none uppercase font-bold text-xs text-violet-500 border-violet-500 focus:outline-none hover:border-violet-400 active:border-violet-600"
          >
            Upload PDF
          </Link>
          <Link
            to="transactions/new"
            role="button"
            className="py-2 px-4 rounded border-0 shadow uppercase font-bold text-sm text-white bg-violet-500 focus:outline-none hover:bg-violet-400 hover:shadow-md active:bg-violet-600"
          >
            Add
          </Link>
        </>
      }
    >
      <Table>
        <thead>
          <tr>
            {headings.map((heading) => (
              <HeadingCell key={heading}>{heading}</HeadingCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {collection.docs
            .slice((page - 1) * numRows, page * numRows)
            .map((doc) => (
              <Transaction
                key={doc.id}
                doc={doc}
                includeStatus={status == null}
              />
            ))}
        </tbody>
      </Table>
      <hr />
      <TablePagination
        current={page}
        total={numPages}
        onNext={nextPage}
        onPrev={prevPage}
        onJump={goToPage}
      />
    </Card>
  );
}

function Transaction({ doc, includeStatus }) {
  const trx = doc.data();
  const formatDate = format("ccc dd LLL hh:mm a (O)");
  return (
    <tr>
      <RowHeadingCell>#{trx.id}</RowHeadingCell>
      <Cell>{trx.amount}</Cell>
      {includeStatus ? (
        <Cell>
          <i
            className={classNames("fas fa-circle mr-2", {
              "text-sky-500": trx.status === "new",
              "text-red-500": trx.status === "refunded",
              "text-green-500 ": trx.status === "confirmed",
            })}
          ></i>{" "}
          {statusLabels[trx.status]}
        </Cell>
      ) : null}
      <Cell>{formatDate(trx.time.toDate())}</Cell>
    </tr>
  );
}

export default Transactions;
