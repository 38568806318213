const { useUser, useIdTokenResult } = require("reactfire");

export default function useRoleCheck() {
  const { data: user } = useUser();
  const {
    data: { claims },
  } = useIdTokenResult(user);
  return {
    user: user,
    isAdmin: !!claims.admin,
    isModerator: !!claims.moderator,
    isInspector: !!claims.inspector,
  };
}
