import classNames from "classnames";
import Card from "components/Cards/Card.js";
import { firestoreBase } from "constants.js";
import { format } from "date-fns";
import React, { useState } from "react";
import { useFirestore } from "reactfire";

const initialState = () => ({
  id: "",
  amount: "",
  date: format(new Date(), "yyyy-MM-dd"),
  time: format(new Date(), "HH:mm"),
});

export default function NewTransaction() {
  const [trxInfo, setTrxInfo] = useState(initialState());
  const handleChange = ({ target: { name, value } }) => {
    setTrxInfo((trxInfo) => ({
      ...trxInfo,
      [name]: value,
    }));
  };
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(null);
  const [errors, setErrors] = useState({});
  const validate = () => {
    let valid = true;
    const errors = {};
    if (!trxInfo.id) {
      valid = false;
      errors.id = "The transaction's ID is required";
    }
    if (!trxInfo.amount) {
      valid = false;
      errors.amount = "The transaction's amount is required";
    } else if (Number.isNaN(Number.parseFloat(trxInfo.amount))) {
      valid = false;
      errors.amount = "The transaction's amount must be a valid number";
    }
    if (!trxInfo.date) {
      valid = false;
      errors.date = "The transaction's date is required";
    }
    if (!trxInfo.time) {
      valid = false;
      errors.time = "The transaction's time is required";
    }
    setErrors(errors);
    return valid;
  };
  const firestore = useFirestore();
  const fieldValue = useFirestore.FieldValue;
  const onSave = async () => {
    if (validate()) {
      setSaving(true);
      const { amount, date, time, ...attrs } = trxInfo;
      try {
        const doc = await firestore
          .doc(firestoreBase)
          .collection("transactions")
          .doc(attrs.id)
          .get();
        if (!doc.exists) {
          await doc.ref.set({
            ...attrs,
            amount: Number.parseFloat(amount),
            time: new Date(Date.parse(`${date}T${time}+02:00`)),
            status: "new",
            addedAt: fieldValue.serverTimestamp(),
          });
          setSaved(trxInfo);
          setTrxInfo(initialState);
        } else {
          return setErrors((errors) => ({
            ...errors,
            id: "A transaction by this ID already exists!",
          }));
        }
      } finally {
        setSaving(false);
      }
    }
  };
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Card
            title="Log Transaction"
            action={
              <button
                type="button"
                onClick={onSave}
                disabled={saving}
                className={classNames(
                  "py-2 px-4 rounded border-0 shadow hover:shadow-md focus:outline-none",
                  "uppercase font-bold text-xs text-white",
                  {
                    "bg-violet-500 hover:bg-violet-400 active:bg-violet-600":
                      !saving,
                    "bg-trueGray-200": saving,
                  }
                )}
              >
                Save
              </button>
            }
          >
            <div className="px-4 lg:px-10 pb-10 pt-1 bg-blueGray-100">
              <form>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Identification
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        ID
                      </label>
                      <input
                        type="text"
                        className={classNames(
                          "border-0 px-3 py-3 rounded shadow focus:outline-none w-full",
                          "focus:ring ease-linear transition-all duration-150",
                          "text-sm",
                          {
                            "placeholder-blueGray-300 text-blueGray-600":
                              errors.id == null,
                            "ring ring-red-500 focus:ring-red-500 placeholder-red-300 text-red-600":
                              errors.id != null,
                          },
                          {
                            "bg-white": !saving,
                            "bg-trueGray-100": saving,
                          }
                        )}
                        placeholder="XXXXXXXXXXX"
                        name="id"
                        disabled={saving}
                        value={trxInfo.id}
                        onChange={handleChange}
                      />
                      {errors.id ? (
                        <p className="text-xs text-red-600 mt-2 text-right">
                          {errors.id}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Amount (SDG)
                      </label>
                      <input
                        type="number"
                        className={classNames(
                          "border-0 px-3 py-3 rounded shadow focus:outline-none w-full",
                          "focus:ring ease-linear transition-all duration-150",
                          "text-sm",
                          {
                            "placeholder-blueGray-300 text-blueGray-600":
                              errors.amount == null,
                            "ring ring-red-500 focus:ring-red-500 placeholder-red-300 text-red-600":
                              errors.amount != null,
                          },
                          {
                            "bg-white": !saving,
                            "bg-trueGray-100": saving,
                          }
                        )}
                        placeholder="000000.00"
                        name="amount"
                        disabled={saving}
                        value={trxInfo.amount}
                        onChange={handleChange}
                      />
                      {errors.amount ? (
                        <p className="text-xs text-red-600 mt-2 text-right">
                          {errors.amount}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Date
                      </label>
                      <input
                        type="date"
                        className={classNames(
                          "border-0 px-3 py-3 rounded shadow focus:outline-none w-full",
                          "focus:ring ease-linear transition-all duration-150",
                          "text-sm",
                          {
                            "placeholder-blueGray-300 text-blueGray-600":
                              errors.date == null,
                            "ring ring-red-500 focus:ring-red-500 placeholder-red-300 text-red-600":
                              errors.date != null,
                          },
                          {
                            "bg-white": !saving,
                            "bg-trueGray-100": saving,
                          }
                        )}
                        name="date"
                        disabled={saving}
                        value={trxInfo.date}
                        onChange={handleChange}
                      />
                      {errors.date ? (
                        <p className="text-xs text-red-600 mt-2 text-right">
                          {errors.date}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Time
                      </label>
                      <input
                        type="time"
                        className={classNames(
                          "border-0 px-3 py-3 rounded shadow focus:outline-none w-full",
                          "focus:ring ease-linear transition-all duration-150",
                          "text-sm",
                          {
                            "placeholder-blueGray-300 text-blueGray-600":
                              errors.time == null,
                            "ring ring-red-500 focus:ring-red-500 placeholder-red-300 text-red-600":
                              errors.time != null,
                          },
                          {
                            "bg-white": !saving,
                            "bg-trueGray-100": saving,
                          }
                        )}
                        name="time"
                        disabled={saving}
                        value={trxInfo.time}
                        onChange={handleChange}
                      />
                      {errors.time ? (
                        <p className="text-xs text-red-600 mt-2 text-right">
                          {errors.time}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {saved ? (
              <div className="pt-2 pb-1 px-6 rounded-b text-xs text-right bg-violet-500 text-white font-bold">
                Transaction #{saved.id} (SDG {saved.amount}) has been saved
              </div>
            ) : null}
          </Card>
        </div>
      </div>
    </>
  );
}
