import classNames from "classnames";
import Card from "components/Cards/Card.js";
import LoadingTableCard from "components/Cards/LoadingTableCard.js";
import SearchBar from "components/SearchBars/SearchBar.js";
import Cell from "components/Table/Cell.js";
import HeadingCell from "components/Table/HeadingCell.js";
import Table from "components/Table/Table.js";
import TablePagination from "components/Table/TablePagination.js";
import { firestoreBase, OrderStatus } from "constants.js";
import { format } from "date-fns";
import useRoleCheck from "hooks/useRoleCheck.js";
import PropTypes from "prop-types";
import React, { Suspense, useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollection,
  useFirestoreDocDataOnce,
} from "reactfire";
import { insertIf } from "utils.js";

export default function Orders({ status, ...props }) {
  const location = useLocation();
  let statusQuery = status;
  if (statusQuery == null) {
    statusQuery = new URLSearchParams(location.search).get("status");
  }
  if (props.title == null) {
    props.title = "Requests";
    if (statusQuery) {
      props.title = `${OrderStatus[statusQuery]} Requests`;
    }
  }
  const headings = [
    "Name",
    "Phone Number",
    ...insertIf(statusQuery == null, "Status"),
    "Time",
    "Assignment",
  ];
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Suspense
            fallback={
              <LoadingTableCard
                {...props}
                headings={headings}
                status={statusQuery}
              />
            }
          >
            <OrdersCard headings={headings} {...props} status={statusQuery} />
          </Suspense>
        </div>
      </div>
    </>
  );
}

Orders.defaultProps = {
  numRows: 10,
  noSearch: false,
};

Orders.propTypes = {
  title: PropTypes.string,
  status: PropTypes.oneOf([
    "new",
    "confirmed",
    "ignored",
    "expired",
    "error",
    "fulfilled",
  ]),
  numRows: PropTypes.number.isRequired,
  noSearch: PropTypes.bool.isRequired,
  action: PropTypes.node,
};

function OrdersCard({ title, headings, status, numRows, action, noSearch }) {
  const firestore = useFirestore();
  const FieldPath = useFirestore.FieldPath;
  const { user, isModerator } = useRoleCheck();
  const [searchString, setSearchString] = useState("");
  const query = useMemo(() => {
    let query = firestore.doc(firestoreBase).collection("orders");
    if (isModerator) {
      const userRef = firestore
        .doc(firestoreBase)
        .collection("staff")
        .doc(user.uid);
      query = query.where("assignedTo", "in", [userRef, false]);
    }
    if (status != null) {
      query = query.where("status", "==", status);
    }
    if (searchString?.length > 3) {
      query = query
        .orderBy(FieldPath.documentId())
        .startAt(searchString)
        .endAt(searchString + "\uf8ff");
    }
    return query;
  }, [firestore, FieldPath, searchString, user.uid, isModerator, status]);
  const { data: collection } = useFirestoreCollection(query);

  const location = useLocation();
  const startPage =
    location.state?.page ?? (collection.docs.length > 0 ? 1 : 0);
  const [page, setPage] = useState(startPage);
  const numPages = Math.ceil(collection.docs.length / numRows);

  const history = useHistory();
  const nextPage = useCallback(
    () => setPage((page) => Math.min(page + 1, numPages)),
    [numPages]
  );
  const prevPage = useCallback(
    () => setPage((page) => Math.max(1, page - 1)),
    []
  );
  const goToPage = useCallback(
    (newPage) => setPage(Math.min(Math.max(1, newPage), numPages)),
    [numPages]
  );
  const handleClick = useCallback(
    (orderId, event) => {
      if (event.metaKey || event.ctrlKey) {
        const win = window.open(`/admin/orders/${orderId}`, "_blank");
        win?.focus();
      } else {
        history.push({
          pathname: `/admin/orders/${orderId}`,
          state: {
            from: {
              ...location,
              state: {
                ...location.state,
                page,
              },
            },
          },
        });
      }
    },
    [history, location, page]
  );
  return (
    <Card
      title={title}
      action={
        <div className="flex flex-row gap-2">
          {noSearch || (
            <SearchBar
              placeholder="Search using request ID..."
              onChange={(q) => setSearchString(q)}
            />
          )}
          {action}
        </div>
      }
    >
      <Table>
        <thead>
          <tr>
            {headings.map((heading) => (
              <HeadingCell key={heading}>{heading}</HeadingCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {collection.docs
            .slice()
            .sort(
              (lhs, rhs) =>
                rhs.get("time").toMillis() - lhs.get("time").toMillis()
            )
            .slice((page - 1) * numRows, page * numRows)
            .map((doc) => (
              <Order
                key={doc.id}
                doc={doc}
                includeStatus={status == null}
                onClick={handleClick}
              />
            ))}
        </tbody>
      </Table>
      <hr />
      <TablePagination
        current={page}
        total={numPages}
        onNext={nextPage}
        onPrev={prevPage}
        onJump={goToPage}
      />
    </Card>
  );
}

function Order({ doc, includeStatus, onClick }) {
  const order = doc.data();
  const handleClick = useCallback(
    (event) => onClick(doc.id, event),
    [doc.id, onClick]
  );
  const { user, isModerator } = useRoleCheck();
  const userRef = useFirestore()
    .doc(firestoreBase)
    .collection("staff")
    .doc(user.uid);
  return (
    <tr
      onClick={handleClick}
      className="hover:bg-blueGray-50 hover:cursor-pointer"
    >
      <Cell>{order.name}</Cell>
      <Cell>{order.call_phone}</Cell>
      {includeStatus ? (
        <Cell>
          <i
            className={classNames("fas fa-circle mr-2", {
              "text-sky-400": order.status === "new",
              "text-amber-300": order.status === "expired",
              "text-gray-400": order.status === "ignored",
              "text-indigo-500 ": order.status === "confirmed",
              "text-red-500": order.status === "error",
              "text-emerald-500": order.status == "fulfilled",
            })}
          ></i>{" "}
          {OrderStatus[order.status]}
        </Cell>
      ) : null}
      <Cell>{format(order.time.toDate(), "ccc dd LLL hh:mm a (O)")}</Cell>
      <Cell>
        {isModerator ? (
          <input
            type="checkbox"
            className="form-checkbox rounded-sm border-1 border-primary-300 text-primary-400 ml-1 w-5 h-5 ease-linear transition-all duration-150"
            checked={order.assignedTo?.path == userRef.path}
            readOnly={true}
          />
        ) : order.assignedTo ? (
          <OrderAssignment order={order} />
        ) : (
          "None"
        )}
      </Cell>
    </tr>
  );
}

function OrderAssignment({ order }) {
  const { data: assignedUser } = useFirestoreDocDataOnce(order.assignedTo);
  return assignedUser?.name ?? "N/A";
}
