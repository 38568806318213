import Card from "components/Cards/Card.js";
import LoadingBar from "components/Loading/LoadingBar.js";
import HeadingCell from "components/Table/HeadingCell.js";
import Table from "components/Table/Table.js";
import PropTypes from "prop-types";

function LoadingTableCard({ title, headings, action }) {
  return (
    <Card title={title} action={action}>
      <Table>
        <thead>
          <tr>
            {headings.map((heading) => (
              <HeadingCell key={heading}>{heading}</HeadingCell>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={headings.length}>
              <LoadingBar />
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}

LoadingTableCard.propTypes = {
  title: PropTypes.string.isRequired,
  headings: PropTypes.arrayOf(PropTypes.string).isRequired,
  action: PropTypes.node,
};

export default LoadingTableCard;
