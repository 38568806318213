import FooterSmall from "components/Footers/FooterSmall.js";
import LoadingPage from 'components/Loading/LoadingPage.js';
import Navbar from "components/Navbars/AuthNavbar.js";
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import backgroundImage from "assets/img/register_bg_2.png";

export default function Auth() {
  return (
    <>
      <Navbar />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          ></div>
          <Suspense fallback={<LoadingPage />}>
            <Switch>
              <Route path="/auth/login" exact component={Login} />
              <Route path="/auth/register" exact component={Register} />
              <Redirect from="/auth" to="/auth/login" />
            </Switch>
          </Suspense>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
