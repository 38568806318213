import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { useFirestoreCollection } from "reactfire";

function CardStats(props) {
  return (
    <Suspense fallback={<Placeholder />}>
      <Stats {...props} />
    </Suspense>
  );
}
CardStats.defaultProps = {
  sortField: "time",
  calculate: (docs) => docs.length,
};

CardStats.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  calculate: PropTypes.func.isRequired,
  sortField: PropTypes.string.isRequired,
  start: PropTypes.instanceOf(Date).isRequired,
  before: PropTypes.instanceOf(Date).isRequired,
};

function Placeholder({ name, description, icon, iconColor }) {
  return (
    <Body
      statSubtitle={name}
      statTitle="LOADING"
      statPercent="..."
      statPercentColor="text-gray-600"
      statDescription={description}
      statIconName={`fas ${icon}`}
      statIconColor={iconColor}
    />
  );
}

function Stats({
  icon,
  iconColor,
  name,
  description,
  query,
  sortField,
  calculate,
  start,
  before,
}) {
  const {
    data: { docs: statDocs },
  } = useFirestoreCollection(query.where(sortField, ">", start));
  const {
    data: { docs: beforeDocs },
  } = useFirestoreCollection(
    query.where(sortField, "<=", start).where(sortField, ">", before)
  );
  const value = calculate(statDocs);
  const beforeValue = calculate(beforeDocs);
  let percent = "N/A";
  if (beforeValue !== 0) {
    percent = ((Math.abs(value - beforeValue) * 100) / beforeValue).toFixed(2);
  }
  return (
    <Body
      statSubtitle={name}
      statTitle={value.toString()}
      statArrow={
        value > beforeValue ? "up" : value < beforeValue ? "down" : "none"
      }
      statPercent={percent}
      statPercentColor={
        value > beforeValue
          ? "text-emerald-600"
          : value === beforeValue
          ? "text-gray-600"
          : percent <= 2
          ? "text-orange-600"
          : "text-red-600"
      }
      statDescription={description}
      statIconName={`fas ${icon}`}
      statIconColor={iconColor}
    />
  );
}

function Body({
  statSubtitle,
  statTitle,
  statArrow,
  statPercent,
  statPercentColor,
  statDescription,
  statIconName,
  statIconColor,
}) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                {statSubtitle}
              </h5>
              <span className="font-semibold text-xl text-blueGray-700">
                {statTitle}
              </span>
            </div>
            <div className="relative w-auto pl-4 flex-initial">
              <div
                className={
                  "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full " +
                  statIconColor
                }
              >
                <i className={statIconName}></i>
              </div>
            </div>
          </div>
          <p className="text-sm text-blueGray-400 mt-4">
            <span className={statPercentColor + " mr-2"}>
              <i
                className={classNames("fas", {
                  "fa-arrow-up": statArrow === "up",
                  "fa-arrow-down": statArrow === "down",
                  "fa-minus": statArrow === "none",
                })}
              ></i>{" "}
              {statPercent}%
            </span>
            <span className="whitespace-nowrap">{statDescription}</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default CardStats;
