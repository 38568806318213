import LoadingPage from "components/Loading/LoadingPage.js";
import PropTypes from "prop-types";
import { Suspense } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useSigninCheck } from "reactfire";

function PrivateRoute(props) {
  return (
    <Suspense fallback={<LoadingPage text="Logging In..." />}>
      <AuthCheck {...props} />
    </Suspense>
  );
}

PrivateRoute.defaultProps = {
  admin: false,
};

PrivateRoute.propTypes = {
  admin: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
};

function AuthCheck({ component: Component, admin, ...rest }) {
  const location = useLocation();

  const requiredClaims = {};
  if (admin) {
    requiredClaims.admin = true;
  }
  const { data: signInCheckResult } = useSigninCheck({ requiredClaims });

  if (signInCheckResult.signedIn && signInCheckResult.hasRequiredClaims) {
    return <Component {...rest} />;
  }

  return (
    <Redirect
      to={{
        pathname: "/auth/login",
        state: { from: location.pathname },
      }}
    />
  );
}

export default PrivateRoute;
