import React from "react";
import { Link } from "react-router-dom";
import Orders from "views/admin/Orders.js";

function Dashboard() {
  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full px-4">{/*<SalesBreakdown />*/}</div>
      <div className="w-full px-4">
        <Orders
          title="New Requests"
          status="new"
          numRows={3}
          noSearch
          action={
            <Link
              to="/admin/orders?status=new"
              role="button"
              className="material-button text-xs text-white hover:text-white bg-primary-500 hover:bg-primary-400 active:bg-primary-600"
            >
              More
            </Link>
          }
        />
      </div>
      <div className="w-full px-4">
        <Orders
          title="Reviewed"
          status="confirmed"
          numRows={3}
          noSearch
          action={
            <Link
              to="/admin/orders?status=confirmed"
              role="button"
              className="material-button text-xs text-white hover:text-white bg-primary-500 hover:bg-primary-400 active:bg-primary-600"
            >
              More
            </Link>
          }
        />
      </div>
      <div className="w-full px-4">
        <Orders
          title="Confirmed"
          status="fulfilled"
          numRows={3}
          noSearch
          action={
            <Link
              to="/admin/orders?status=fulfilled"
              role="button"
              className="material-button text-xs text-white hover:text-white bg-primary-500 hover:bg-primary-400 active:bg-primary-600"
            >
              More
            </Link>
          }
        />
      </div>
    </div>
  );
}

export default Dashboard;
