import PropTypes from "prop-types";
import React from "react";

function SearchBar({ placeholder, onChange }) {
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="relative flex w-full flex-wrap items-stretch">
        <input
          type="text"
          placeholder={placeholder}
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
          onChange={(e) => onChange(e.target.value)}
        />
        <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
          <i className="fas fa-search"></i>
        </span>
      </div>
    </form>
  );
}

SearchBar.defaultProps = {
  placeholder: "Search here...",
};

SearchBar.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
};

export default SearchBar;
